import Headroom from 'headroom.js';
import { getOffset } from '../utils';

export default class Header {
  constructor() {
    this.DOM = {};
    this.DOM.header = document.querySelector('.js-header');
    this.DOM.menuLinks = document.querySelectorAll('.js-menu-link');

    this.init();
  }

  init() {
    this.initPlugins();
    this.addListeners();
  }

  initPlugins() {
    const headroom = new Headroom(this.DOM.header);

    headroom.init();
  }

  addListeners() {
    this.DOM.menuLinks.forEach((menuLink) => {
      menuLink.addEventListener('click', (e) => {
        e.preventDefault();
        const sectionHash = e.currentTarget.hash;

        this.scrollToSection(sectionHash, true);
      });
    });
  }

  scrollToSection(sectionHash, pushState) {
    let sectionEl;

    if (sectionHash.length) {
      sectionEl = document.querySelector(sectionHash);
    }

    if (sectionEl) {
      const doc = document.documentElement;
      const pageScrollTop = doc.scrollTop;
      const sectionOffsetTop = getOffset(sectionEl).top;
      let offset;

      if (pageScrollTop < sectionOffsetTop) {
        offset = sectionOffsetTop;
      } else {
        offset = sectionOffsetTop - this.DOM.header.offsetHeight;
      }

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });

      if (pushState) {
        window.history.pushState(null, null, sectionHash);
      }
    }
  }
}
