const colophon = () => {
  const yearElement = document.getElementById('js-colophon-year');
  const currentYear = new Date().getFullYear();

  if (yearElement) {
    yearElement.innerHTML = currentYear;
  }
};

export default colophon;
