/*
  Project: Vensights
  Author: xFive
 */

import colophon from './modules/colophon';
import Header from './modules/header';

colophon();
// eslint-disable-next-line no-unused-vars
const header = new Header();
