// Get top and Left body scroll
export function pageScroll() {
  const docElem = document.documentElement;

  return {
    top: window.pageYOffset || docElem.scrollTop,
    left: window.pageXOffset || docElem.scrollLeft,
  };
}

// Get top and Left positions relatives to body
export function getOffset(element) {
  const rect = element.getBoundingClientRect();

  return {
    top: rect.top + pageScroll().top,
    left: rect.left + pageScroll().left,
  };
}
